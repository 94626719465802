import React, { useState, useEffect, useRef } from 'react';
import { sendAiChatRequest } from '../../services/openAi';
import icon from '../../assets/images/WBI-Icon.png';
import ChatControls from './ChatControls/ChatControls';
import "./ChatBot.css";

const ChatBot = (props) => {
  const name = 'Cyborg Assistant';
  const initial_message = "I'm your personal Cyborg Assistant, how can I help?";

  const [query, setQuery] = useState('');
  const [aiMessages, setAiMessages] = useState([{ role: 'assistant', content: initial_message }]);
  const [userMessage, setUserMessage] = useState({ role: 'user', content: '' });
  const [assistantMessage, setAssistantMessage] = useState({ role: 'assistant', content: '' });
  const [isDisabled, setIsDisabled] = useState(false);

  const chatbox = useRef(null);

  useEffect(() => {
    if (chatbox.current) {
      chatbox.current.scrollTop = chatbox.current.scrollHeight;
    }
  }, [aiMessages, userMessage, assistantMessage]);

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (query.length === 0 || isDisabled) return;

    const userQuery = query; // Save the current query to a local variable

    setUserMessage({ role: 'user', content: userQuery });
    setIsDisabled(true);
    setQuery(''); // Clear the query immediately after setting the user message

    let result = '';
    let dotCount = 1;
    const dotTimer = setInterval(() => {
      let dots = '. '.repeat(dotCount);
      setAssistantMessage({ role: 'assistant', content: dots });
      dotCount = (dotCount % 3) + 1;
    }, 1000);

    try {
      const response = await sendAiChatRequest({
        method: 'POST',
        endpoint: 'chat',
        body: { query: userQuery },
      });

      result = response.data.message.value;

    } catch (error) {
      const errorMessage = error.response?.data || "An unexpected error occurred.";
      result = `Error: ${errorMessage}`;
      console.error('Failed to query the AI:', error);
    } finally {
      clearInterval(dotTimer);
      setAiMessages(prevState => ([...prevState, { role: 'user', content: userQuery }, { role: 'assistant', content: result }]));
      setUserMessage({ role: 'user', content: '' });
      setAssistantMessage({ role: 'assistant', content: '' });
      setIsDisabled(false);
    }
  };

  const updateQuery = (e) => {
    setQuery(e.target.value);
  };

  const refresh = () => {
    setAiMessages([{ role: 'assistant', content: initial_message }]);
    setUserMessage({ role: 'user', content: '' });
    setAssistantMessage({ role: 'assistant', content: '' });
    setIsDisabled(false);
  };

  const messageHTML = (message, index) => {
    let className = message.role === 'user' ? 'bg-primary ml-auto text-white' : 'bg-gray-200 mr-auto min-w-[36px]';
    className += message.content !== '' ? ' display-inherit' : ' hidden';
    className += ' max-w-[95%] m-2 rounded-md p-2 w-[fit-content]';

    return (
      <li className={className} key={index}>
        <p className='whitespace-pre-line'>{message.content}</p>
      </li>
    );
  };

  return (
    <div className='rounded-md h-full'>
      <div id='chatbox-div' className='h-full bg-white border border-gray-200 rounded-md flex flex-col justify-between'>
        <div className='p-2 border-b border-gray-300 h-12 mb-2'>
          <div className='flex items-center'>
            <img
              className='w-8 h-8 rounded-full'
              src={icon}
              alt='pfp'
            />
            <span className='pl-2 text-xl font-medium'>{name}</span>
          </div>
        </div>
        <div className='overflow-auto h-full' ref={chatbox}>
          <ul>
            {aiMessages.filter(message => message.role === 'user' || message.role === 'assistant').map((message, index) => messageHTML(message, index))}
            {messageHTML(userMessage, aiMessages.length)}
            {messageHTML(assistantMessage, aiMessages.length + 1)}
          </ul>
        </div>
        <ChatControls
          query={query}
          isDisabled={isDisabled}
          updateQuery={updateQuery}
          handleSubmitClick={handleSubmitClick}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default ChatBot;